@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-family: Montserrat, sans-serif; */
    font-family: 'Mulish','Noto Sans TC', sans-serif;
  }
}


body{
  // background-color: var(--bodyColor);
}


.grecaptcha-badge { visibility: hidden; }
.swiper-button-prev , .swiper-button-next{
  color: white !important;
} 
/* Scroll Bar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #3c3c3c;
  border-radius: 5px;
} 
::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 5px;
}
::-webkit-scrollbar-button {
  background-color: #555;
  height: 0;
}
::-webkit-scrollbar-corner {
  background-color: #555;
}
.chatroom::-webkit-scrollbar {
  display: none;
}



.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
}


#headerBg{
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 60s;
  transform: translateX(0%)
}
#headerBg2{
  -webkit-animation: linear infinite;
  -webkit-animation-name: run2;
  -webkit-animation-duration: 60s;
  transform: translateX(0%)
}
#headerBg3{
  -webkit-animation: linear infinite;
  -webkit-animation-name: run3;
  -webkit-animation-duration: 60s;
  transform: translateX(-100%)
}
@keyframes run {
  0% {
    transform: translateX(30%); 
  }
  50% {
    transform: translateX(0%); 
  }
  100% {
    transform: translateX(-30%);    
  }
}
@keyframes run2 {
  0% {
    transform: translateX(0%); 
  }

  100% {
    transform: translateX(-100%);    
  }
}
@keyframes run3 {
  0% {
    transform: translateX(-100%); 
  }

  100% {
    transform: translateX(0%);    
  }
}
.scroll-down{
  border: 2px solid #fff;
  border-radius: 20px;
  bottom: 25px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 30px;
  &::before{
    animation: scrollDownAnimation 2s infinite;
    background-color: #fff;
    border-radius: 100%;
    content: '';
    height: 6px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 6px;
  }
}
.perspective{
  perspective:1px;
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}

@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}
.fp-watermark{
  display: none;
}
#fp-nav ul li a span, 
.fp-slidesNav ul li a span{
  background: #cccccc !important;
}


:root {
  --toggle-bg-off: #e2e8f0;
  --toggle-bg-on: #10b981;
  --toggle-shadow: rgba(0, 0, 0, 0.1);
  --toggle-translate: calc(100% - 1.25rem);
}

/* ... */

button.toggle-button {
  background-color: var(--toggle-bg-off);
  border-color: var(--toggle-bg-off);
}

button.toggle-button:hover {
  background-color: var(--toggle-bg-on);
  border-color: var(--toggle-bg-on);
}

button.toggle-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--toggle-bg-on), 0 0 0 4px var(--toggle-shadow);
}

button.toggle-button.active {
  background-color: var(--toggle-bg-on);
  border-color: var(--toggle-bg-on);
}

button.toggle-button .toggle-switch {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

button.toggle-button.active .toggle-switch {
  transform: translateX(var(--toggle-translate));
}


/*loading animation 001*/
#load {
  position:absolute;
  width:600px;
  height:36px;
  left:50%;
  top:40%;
  margin-left:-300px;
  overflow:visible;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  cursor:default;
}

#load div {
  position:absolute;
  width:20px;
  height:36px;
  opacity:0;
  font-family:Helvetica, Arial, sans-serif;
  animation:move 2s linear infinite;
  -o-animation:move 2s linear infinite;
  -moz-animation:move 2s linear infinite;
  -webkit-animation:move 2s linear infinite;
  transform:rotate(180deg);
  -o-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
}

#load div:nth-child(2) {
  animation-delay:0.2s;
  -o-animation-delay:0.2s;
  -moz-animation-delay:0.2s;
  -webkit-animation-delay:0.2s;
}
#load div:nth-child(3) {
  animation-delay:0.4s;
  -o-animation-delay:0.4s;
  -webkit-animation-delay:0.4s;
  -webkit-animation-delay:0.4s;
}
#load div:nth-child(4) {
  animation-delay:0.6s;
  -o-animation-delay:0.6s;
  -moz-animation-delay:0.6s;
  -webkit-animation-delay:0.6s;
}
#load div:nth-child(5) {
  animation-delay:0.8s;
  -o-animation-delay:0.8s;
  -moz-animation-delay:0.8s;
  -webkit-animation-delay:0.8s;
}
#load div:nth-child(6) {
  animation-delay:1s;
  -o-animation-delay:1s;
  -moz-animation-delay:1s;
  -webkit-animation-delay:1s;
}
#load div:nth-child(7) {
  animation-delay:1.2s;
  -o-animation-delay:1.2s;
  -moz-animation-delay:1.2s;
  -webkit-animation-delay:1.2s;
}
#load div:nth-child(8) {
  animation-delay:1.4s;
  -o-animation-delay:1.4s;
  -moz-animation-delay:1.4s;
  -webkit-animation-delay:1.4s;
}

@keyframes move {
  0% {
    left:0;
    opacity:0;
  }
	35% {
		left: 41%; 
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		-o-transform:rotate(0deg);
		transform:rotate(0deg);
		opacity:1;
	}
	65% {
		left:59%; 
		-moz-transform:rotate(0deg); 
		-webkit-transform:rotate(0deg); 
		-o-transform:rotate(0deg);
		transform:rotate(0deg); 
		opacity:1;
	}
	100% {
		left:100%; 
		-moz-transform:rotate(-180deg); 
		-webkit-transform:rotate(-180deg); 
		-o-transform:rotate(-180deg); 
		transform:rotate(-180deg);
		opacity:0;
	}
}

@-moz-keyframes move {
	0% {
		left:0; 
		opacity:0;
	}
	35% {
		left:41%; 
		-moz-transform:rotate(0deg); 
		transform:rotate(0deg);
		opacity:1;
	}
	65% {
		left:59%; 
		-moz-transform:rotate(0deg); 
		transform:rotate(0deg);
		opacity:1;
	}
	100% {
		left:100%; 
		-moz-transform:rotate(-180deg); 
		transform:rotate(-180deg);
		opacity:0;
	}
}

@-webkit-keyframes move {
	0% {
		left:0; 
		opacity:0;
	}
	35% {
		left:41%; 
		-webkit-transform:rotate(0deg); 
		transform:rotate(0deg); 
		opacity:1;
	}
	65% {
		left:59%; 
		-webkit-transform:rotate(0deg); 
		transform:rotate(0deg); 
		opacity:1;
	}
	100% {
		left:100%;
		-webkit-transform:rotate(-180deg); 
		transform:rotate(-180deg); 
		opacity:0;
	}
}

@-o-keyframes move {
	0% {
		left:0; 
		opacity:0;
	}
	35% {
		left:41%; 
		-o-transform:rotate(0deg); 
		transform:rotate(0deg); 
		opacity:1;
	}
	65% {
		left:59%; 
		-o-transform:rotate(0deg); 
		transform:rotate(0deg); 
		opacity:1;
	}
	100% {
		left:100%; 
		-o-transform:rotate(-180deg); 
		transform:rotate(-180deg); 
		opacity:0;
	}
}


/* loading animation 002*/

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
#logospin01{
  animation: lds-hourglass 2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #87f88f transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #484646;
  border-bottom-color: #63d42f;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 



.swiper-backface-hidden .swiper-slide{
    // margin-bottom: 40px;
  }
.swiper-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal, 
.swiper-pagination-custom, .swiper-pagination-fraction {
      bottom: 5px !important;
  }
.swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #ffffff;
    opacity: 1 !important;
    background: #ffffff60 !important;
    transition: all .5s;
  }
  
.swiper-pagination-bullet-active {
    color: #fff;
    background: #ffffff !important;
    border-radius: 25px !important;
  }




  // glow effect

  div.glow:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    border-radius: 50%;
    transform: scale(0.8);
    -webkit-filter: blur(12vw);
    -moz-filter: blur(12vw);
    -ms-filter: blur(12vw);
    filter: blur(12vw);
    background: linear-gradient(270deg, #0fffc1, #7e0fff);
    background-size: 200% 200%;
    animation: animateGlow 10s ease infinite;
    opacity: 0.6;
  }
  @keyframes animateGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes textColor {
    0% {
      color: #7e0fff;
    }
    50% {
      color: #0fffc1;
    }
    100% {
      color: #7e0fff;
    }
  }


  //glow effect 02
  .circle {
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #ffffff, #fdfaff);
    border-radius: 50%;
    -webkit-filter: blur(8vw);
    -moz-filter: blur(8vw);
    -ms-filter: blur(8vw);
    filter: blur(10vw);
    transition: 1.8s;    
    transform: scale(1.1);
    animation: opactiy infinite 8s linear;
    opacity: 0.5;
  }
  .circle2 {
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #16f0ba, #7e0fff);
    border-radius: 50%;
    -webkit-filter: blur(8vw);
    -moz-filter: blur(8vw);
    -ms-filter: blur(8vw);
    filter: blur(12vw);
    transition: 1.8s;    
    transform: scale(0.7);
    animation: opactiy2 infinite 8s linear;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
      
    }
    to {
      transform: rotate(365deg);
    }
  }
  @keyframes opactiy {
    0% {
      filter: blur(8vw);

    }
    50% {
      filter: blur(2vw);
    }
    100% {
      filter: blur(8vw);
    }
  }
  @keyframes opactiy2 {
    0% {
      opacity: 0.5;

    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.5;
    }
  }
  @keyframes float_t01 {
    0% {
      transform: translatey(2px);

    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(2px);
    }
  }
  @keyframes float_t02 {
    0% {
      transform: translate(2px,2px);

    }
    50% {
      transform: translate(-10px,-5px);
    }
    100% {
      transform: translate(2px,2px);
    }
  }
  @keyframes float_t03 {
    0% {
      transform: translate(2px,2px);

    }
    50% {
      transform: translate(10px,10px);
    }
    100% {
      transform: translate(2px,2px);
    }
  }
  @keyframes float_center {
    0% {
     transform: translate(-50%,-35%);
    }
    50% {
      transform: translate(-50%,-55%);
    }
    100% {
      transform: translate(-50%,-35%);
    }
  }